import { RemixBrowser } from '@remix-run/react'
import { hydrateRoot } from 'react-dom/client'
import { cacheAssets } from 'remix-utils'

hydrateRoot(document, <RemixBrowser />)

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js')
  })
}

cacheAssets().catch((error) => {
  console.error(error)
})
